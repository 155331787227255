<template>
  <section role="region" class="in-the-media-section" id="in-the-media">
    <TheSecondHead class="header" chipText="Mais sobre nós" titleText="MeLembre na mídia"
      subtitleText="Conquistas e novidades sobre nosso serviço" />
    <div class="cards">
      <TheCard v-for="item in content" :key="item.id" :mediaSrc="item.mediaSrc"
        imageAlt="Logo de veículo de mídia" :thirdHeading="item.text" @button-clicked="seeMore(item.redirectLink)"
        :buttonText="item.buttonText" />
    </div>
  </section>
</template>

<script setup lang="ts">
import { useInTheMediaStore } from '@/stores/home/inTheMedia'
const store = useInTheMediaStore();

const content = store.mediaContent;

const seeMore = (path: string) => window.open(path, '_blank');
</script>

<style lang="scss">
@import "@/styles/variables";

.in-the-media-section {
  background-color: $greyLight;
  max-width: none;
  padding: 40px;

  .header {
    text-align: center;

    .title {
      margin: 16px auto;
      max-width: 240px;
      font-size: 40px;
      line-height: 110%;
    }

    .subtitle {
      max-width: 240px;
      margin: 0px auto 25px;
    }
  }

  .cards {
    display: grid;
    row-gap: 20px;

    .default-card {
      width: 280px;
      height: 281px;
      box-shadow: none;
      justify-content: flex-end;

      .image-box {
        display: flex;
        align-items: center;
        display: flex;
        justify-content: center;
        width: 209px;
        height: 61px;
      }

      h3 {
        max-width: 182px;
        font-weight: 700;
        margin: 8px 0px 18px;
      }

      .default-button {
        width: 135px !important;
        height: 44px !important;
        padding: 11px 25px !important;
        margin-bottom: 43px;
      }
    }
  }

  @media screen and (min-width: 960px) and (max-width: 1199px) {
    padding: 67px 0px 82px;
  }

  @media screen and (min-width: 834px) {
    .header {

      .title,
      .subtitle {
        max-width: none;
      }

      .title {
        margin: 16px 0px 8px;
      }

      .subtitle {
        margin: 0px 0px 40px;
      }
    }

    .cards {
      grid-template-columns: auto auto;
      justify-content: center;
      -moz-column-gap: 30px;
      column-gap: 30px;

      .default-card {
        align-items: flex-start;
        text-align: start;
        padding-left: 34px;

        .image-box {
          width: 203px;
          height: 60px;
          justify-content: flex-start;
        }

        h3 {
          margin: 8px 0px 37px;
          line-height: normal;
        }
      }
    }
  }

  @media screen and (min-width: 1200px) {
    padding: 67px 0px 64px;

    .cards {
      grid-template-columns: auto auto auto auto;
    }
  }
}
</style>