<template>
  <section role="region" class="about-company-section">
    <div class="content" id="about-company">
      <div class="content-image">
        <ThePicture mobilePath="/images/about-company/m-empresaria-sucesso.png"
          tabletPath="/images/about-company/t-empresaria-sucesso.png"
          desktopPath="/images/about-company/empresaria-sucesso.png" mobileQuerie="834px" desktopQuerie="960px"
          altText="Moça com celular na mão sorrindo" />
      </div>
      <div class="content-text">
        <TheSecondHead chipText="Nossa história" chipColor="white" titleText="Quem somos" />
        <p class="paragraph">
          Nascemos em 2022, no coração da <a class="link" href="https://quiteja.com.br/" target="_blank"
            rel="noopener noreferrer">QuiteJá</a>. Fazemos parte também do grupo Sinqia, na unidade de Digital.
          Nosso
          escritório fica em Curitiba, mas nossa atuação é nacional. Temos como propósito <b>aproximar pessoas e
            empresas, facilitando a comunicação entre ambas.</b>
        </p>
        <p class="paragraph">
          Começamos nossa jornada como uma plataforma de otimização de pagamentos, mas com o passar do tempo
          <b>expandimos nossa solução para diversos outros nichos</b>, como avisos de pagamento, atualizações de
          produtos e
          serviços, avisos regulatórios e notificações de logística.
        </p>
        <p class="paragraph">
          Queremos revolucionar a forma como os brasileiros recebem notificações. Muito mais do que lembretes,
          <b>somos a
            comodidade que você ainda não sabia que precisava</b>.
        </p>
      </div>
      <div class="content-seal">
        <NuxtLink to="https://www.quiteja.com.br">
          <picture class="image-component">
            <source srcset="@/assets/images/about-company/selo-quite.png" media="(min-width: 834px)">
            <source srcset="@/assets/images/about-company/m-selo-quite.png" media="(max-width: 833px)">
            <img v-once alt="Selo da QuiteJá" loading="lazy" src="@/assets/images/about-company/m-selo-quite.png" />
          </picture>
        </NuxtLink>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">

</script>

<style lang="scss">
@import "@/styles/variables";

.about-company-section {
  max-width: none;
  background-color: $purpleLight;
}

.about-company-section .content {
  padding: 40px 20px;

  .content-image {
    grid-area: a;
    margin-bottom: 40px;
    text-align: center;

    img {
      width: 319.92px;
      height: 387px;
    }
  }

  .content-text {
    grid-area: b;

    .text-chip,
    .title {
      margin-bottom: 16px;
    }

    .paragraph {
      margin-bottom: 26px;
      line-height: 150%;

      &:last-child {
        margin-bottom: 40px;
      }
    }
  }

  .content-seal {
    grid-area: c;
  }

  @media screen and (min-width: 834px) and (max-width: 959px) {
    grid-template-areas: "b a" "b c";
    padding: 43px 33px 40px;

    .content-image {
      align-self: end;
      margin: 46px 0px 11px;

      img {
        width: 370.347px;
        height: 448px;
      }
    }

    .content-text {
      width: 369px;

      .paragraph {
        margin-bottom: 24px;

        &:last-child {
          margin-bottom: 0px;
        }
      }
    }

    .content-seal {
      align-self: start;
      text-align: center;
    }

  }

  @media screen and (min-width: 960px) and (max-width: 1199px) {
    padding: 68px 32px;

    .content-image {
      margin-bottom: 0px;

      img {
        width: 467.067px;
        height: 565px;
        margin-left: 44px;
      }
    }

    .content-text {

      .text-chip,
      .title {
        margin-bottom: 17px;
      }

      .paragraph {
        margin-bottom: 30px;

        &:last-child {
          margin-bottom: 25px;
        }
      }
    }
  }

  @media screen and (min-width: 834px) {
    display: grid;
  }

  @media screen and (min-width: 960px) {
    grid-template-areas: "b a" "c a";
    align-items: end;
    justify-items: stretch;
    max-width: 1185px;
    margin: 0 auto;

    .content-text {
      max-width: 437px;
    }
  }

  @media screen and (min-width: 1200px) {
    padding: 68px 0px;

    .content-image {
      margin-bottom: 0px;

      img {
        width: 496px;
        height: 600px;
      }
    }

    .content-text {
      max-width: 437px;

      .paragraph,
      .paragraph:last-child {
        margin-bottom: 24px;
      }
    }
  }
}
</style>