
import { defineStore } from 'pinia'

export const useInTheMediaStore = defineStore('inTheMediaStore', {
  state: () => ({
    mediaContent: [
      {
        id: 1,
        mediaSrc: "home/in-the-media/digitips-logo.png",
        text: "Inadimplência: entenda o que é e como evitar",
        buttonText: "Saiba Mais",
        redirectLink: "https://digitips.com.br/inadimplencia-o-que-e-e-como-evitar/"
      },
      {
        id: 2,
        mediaSrc: "home/in-the-media/sinqia-digital-logo.png",
        text: "Régua de Relacionamento Digital",
        buttonText: "Saiba Mais",
        redirectLink: "https://materiais.simply.com.br/ebook-regua-de-relacionamento-digital"
      },
    ] as Array<MediaCardItem>
  }),

  getters: {
    getMediaContent: (state) => state.mediaContent,
  },
})