<template>
  <section role="region" class="how-it-works-section" id="how-it-works" ref="howItWorksSection">
    <div class="steps">
      <TheSecondHead v-show="showSteps" class="default-card understand" :class="makeFade ? 'show' : ''"
        chipText="Passo a passo" titleText="Entenda como nosso serviço funciona" />
      <TheCard :style="`transition-duration: ${(item.id + 1) * 0.6}s !important`" v-show="showSteps"
        :class="makeFade ? 'show' : ''" v-for="item in workflowSteps" :key="item.id" :mediaSrc="item.mediaSrc"
        :imageAlt="item.title" :paragraphText="item.text">
        <template #out-of-box>
          <div class="step-counter">
            <h1 class="number">{{ item.id }}</h1>
          </div>
        </template>
      </TheCard>

    </div>
    <div class="people-background" v-show="showSteps" :class="makeFade ? 'show' : ''"></div>
  </section>
</template>

<script setup lang="ts">
import { ref, onMounted, onBeforeUnmount } from 'vue';
import { useHowItWorksStore } from '@/stores/home/howItWorks';
const store = useHowItWorksStore();

const workflowSteps = store.getSteps;

const showSteps = ref<Boolean>(false);
const makeFade = ref<Boolean>(false);
const howItWorksSection = ref<any>(null);

const howItWorksWatcher = () => {
  var section = howItWorksSection.value;

  if ((window.scrollY + section.offsetHeight) >= section.offsetHeight) {
    setTimeout(() => {
      makeFade.value = true;
    }, 100);
    window.removeEventListener('scroll', howItWorksWatcher);
  }
}

onMounted(() => {
  showSteps.value = true;
  window.addEventListener('scroll', howItWorksWatcher, {passive: true});
  howItWorksWatcher();
})

onBeforeUnmount(() => {
  window.removeEventListener('scroll', howItWorksWatcher);
})
</script>

<style lang="scss">
@import "@/styles/variables";

.how-it-works-section {
  max-width: none !important;
  background-image: url(@/assets/images/home/how-it-works/bg-square-mobile.png);
  background-color: $purpleLight;
  background-repeat: repeat;
  background-position: center top;
  padding: 42px 40px 40px 41px;
  border-top: 2px solid $blue;
  border-bottom: 2px solid $blue;
  display: flex;
  display: -webkit-flex;
  justify-content: center;
  height: 1398px;

  h2 {
    line-height: 110%;
  }

  .steps {

    .default-card,
    .understand {
      box-shadow: 0px 4.5px 2px 0px rgba(0, 0, 0, 0.15);
    }

    .default-card {
      overflow: unset;
      border: 2px solid $blue;
      width: 280px;
      height: 291px;
      padding: 44px 17px 36px;

      &:nth-child(2),
      &:nth-child(3) {
        margin-bottom: 58px;
      }

      .step-counter {
        margin-top: -82px;
        background-color: $blue;
        border-radius: 50%;
        width: 72px;
        height: 72px;
        display: flex;
        display: -webkit-flex;
        align-items: center;
        justify-content: center;

        h1 {
          color: $white;
          text-align: center;
          line-height: inherit;
        }
      }

      img {
        width: 96px;
        height: 96px;
      }
    }

    .understand {
      display: flex;
      display: -webkit-flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      border-radius: 25px;
      background-color: $white;
      margin: 0px auto 58px;
      padding: 36px 0px;
      width: 280px;
      height: auto;

      .text-chip {
        height: 38px;
        padding: 9px 19px 10px 20px;
        margin-bottom: 15px;
      }
    }
  }

  .people-background {
    display: none;
    opacity: 0;
  }

  @media screen and (min-width: 834px) and (max-width: 959px) {
    height: 655px;
    padding: 40px 32px;
  }

  @media screen and (min-width: 834px) and (max-width: 1599px) {
    .steps {
      display: grid;
      justify-items: center;
      align-items: center;
      justify-content: center;
      grid-template-areas: "a a a" "b c d";

      .default-card {
        width: 247px;
        height: 321px;
        padding: 49px 12px 24px 13px;

        &:nth-child(1) {
          grid-area: a;
        }

        &:nth-child(2) {
          margin: 0;
          grid-area: b;
        }

        &:nth-child(3) {
          grid-area: c;
          margin: 0px 16px;
        }

        &:nth-child(4) {
          grid-area: d;
        }

        .step-counter {
          margin-top: -87px;
        }
      }

      .understand {
        width: 770px;
        height: 178px;
        max-width: 770px;
        padding: 42px 36px;
        margin-bottom: 76px;
      }
    }

  }

  @media screen and (min-width: 960px) and (max-width: 1599px) {
    padding: 102px 33px 62px 32px;
    height: 601px;

    .steps {
      display: grid;
      justify-items: center;
      align-items: center;
      justify-content: center;
      grid-template-areas: "a b c d";

      .default-card,
      .understand {
        width: 200px;
        height: 434px;
        padding: 59px 30px 43px;

        &:nth-child(3) {
          margin: 0px 31px;
        }

        .step-counter {
          margin-top: -95px;
        }
      }

      .understand {
        width: 202px;
        height: 434px;
        max-width: 202px;
        padding: 82px 14px;
        margin-right: 30px;
        margin-bottom: 0px;
      }
    }
  }

  @media screen and (min-width: 1200px) and (max-width: 1599px) {
    padding: 68px 0px;
    height: unset;

  }

  @media screen and (min-width: 834px) {
    h2 {
      font-size: 40px;
    }

    .steps .default-card img {
      width: 112px;
      height: 112px;
    }
  }

  @media screen and (min-width: 1200px) {
    .steps .default-card {
      width: 270px;
      height: 340px;
      max-width: 270px;

      &:first-child {
        padding: 55.26px 14px 0px 13px;
      }
    }
  }

  @media screen and (min-width: 1600px) {

    padding: 0px;
    height: 452px;

    .steps {
      display: flex;
      display: -webkit-flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      column-gap: 30px;
      margin: 66px 0px 43px;
      position: absolute;

      .default-card {
        max-height: 340px;
        margin: 0px !important;
        padding: 52.78px 36px 43.22px 35px;

        &:first-child {

          .text-chip {
            margin-bottom: 12.33px;
          }
        }

        .step-counter {
          margin-top: -90px;

        }
      }
    }

    .people-background {
      width: 100%;
      height: 100%;
      background-image: url(@/assets/images/home/how-it-works/bg-pessoas.png);
      background-repeat: no-repeat;
      background-position: center center;
      z-index: 1;
      opacity: 0;
      -webkit-transform: translateY(113px);
      transform: translateY(113px);
      -webkit-transition: all 3s ease 1s !important;
      -moz-transition: all 3s ease 1s !important;
      -o-transition: all 3s ease 1s !important;
      transition: all 3s ease 1s !important;
      -webkit-transition-duration: 2s !important;
      -moz-transition-duration: 2s !important;
      -o-transition-duration: 2s !important;
      transition-duration: 2s !important;
      display: block;
    }
  }

  // Animação de aparecer
  .default-card {
    opacity: 0;
    -webkit-transform: translateY(113px);
    transform: translateY(113px);
  }

  .show {
    -webkit-transition: all 0.6s ease;
    -moz-transition: all 0.6s ease;
    -o-transition: all 0.6s ease;
    transition: all 0.6s ease;
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
    opacity: 1;
  }

  // Fim animação de aparecer
}
</style>